
<div class="app">
  <div class="auth_block">
    <div class="auth_content">
      <div class="auth_content_wrap">
        <!-- Header -->
        <header class="wrap_header">
          <div class="app_brand">
            <div class="brand_icon">
              <img src="../assets/img/IADVL logo.jpg" alt="" />
            </div>
            <div class="brand_text">
              <h1>Thesis Award Paper Submission</h1>
            </div>
          </div>
        </header>
        <!-- Content -->
       
        <div class="wrap_content">
          <div
          #alert
          class="alert alert-warning alert-dismissible fade show"
          role="alert"
          *ngIf="isShowAlert == true"
        >
          <p>You are not authorized!</p>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            (click)="closeAlert()"
          ></button>
        </div>
          <div class="page_title">
            <h2>Login</h2>
            <p>Please enter your details below</p>
          </div>

          <div class="auth_form">
            <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">

            <div class="form_group">
              <label class="form_label required" for="emailInput">Email  </label>
              <input class="form_control" type="text" id="emailInput" placeholder="mail@website.com" formControlName="Email" />
              <div  *ngIf="formGroup.controls['Email'].invalid && formGroup.controls['Email'].dirty">
              
                <span class="error" *ngIf="formGroup.controls['Email'].errors.required">{{'login.loginForm.email.validation.required' | translate }}</span>
                <span  class="error" *ngIf="formGroup.controls['Email'].errors.pattern && !formGroup.controls['Email'].errors.maxlength && !formGroup.controls['Email'].errors.minlength">{{'login.loginForm.email.validation.invalid' | translate }}</span>
                <span  class="error" *ngIf="formGroup.controls['Email'].errors.maxlength">{{'login.loginForm.email.validation.maxlength' | translate }}</span>
                <span  class="error" *ngIf="formGroup.controls['Email'].errors.minlength">{{'login.loginForm.email.validation.minlength' | translate }}</span>
              </div>

            </div>
            <div class="form_group">
              <label class="form_label required" for="passwordInput">Password</label>
              <input class="form_control" type="password" id="emailInput" placeholder="************" formControlName="Password"/>
              <div *ngIf="formGroup.controls['Password'].invalid && formGroup.controls['Password'].dirty">
                <span class="error" *ngIf="formGroup.controls['Password'].errors.required">{{'login.loginForm.password.validation.required' | translate }}</span>
                <span class="error" *ngIf="formGroup.controls['Password'].errors.maxlength">{{'login.loginForm.password.validation.maxlength' | translate }}</span>
              </div>
            </div>
            
            <div class="form_group form_flex space_between">
              <!-- Remember me -->
              <!-- <div class="custom_checkbox">
                <input class="inp-cbx" id="cbx" type="checkbox">
                <label class="cbx" for="cbx">
                    <span>
                        <svg width="10px" height="8px" viewBox="0 0 12 10">
                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                    </span>
                    <span class="cbx_txt">Remember me</span>
                </label>
              </div> -->
              <!--/. Remember me -->

              <!-- Forgot password -->
              <!-- <div class="forgot_password">
                <a href="javascript:void(0)">Forgot password?</a>
              </div> -->
              <!--/. Forgot password -->
            </div>
            
            <div class="form_group">
              <button type="submit" class="secondary_btn width_100">Login</button>
            </div>
            </form>
            <div class="form_group">
              <button class="secondary_btn width_100" (click)="openOTPModel()">Login Using OTP</button>
            </div>
          </div>

        </div>
        
        <!-- Footer -->
        <footer class="wrap_footer">
          <p class="copyright">© IADVL ACADEMY - All rights reserved.</p>
          <div class="powered_by">
            <p>
              Powered by 
              <a href="#" target="_blank">
                <img src="../assets/img/Communa-Logo.png" alt="" />
              </a>
            </p>
          </div>
        </footer>
      </div>
    </div>
    <div class="auth_right_panel">
        <div class="right_panel_wrap">
          <div class="right_panel_content">
            <!-- <h1>Research Grant Application</h1> -->
            <img src="../../../assets/img/IADVL logo.jpg" alt="" />
          </div>
        </div>
    </div>
  </div>
</div>

<!--help Modal -->
<div class="modal fade" id="otpModal" bsModal #otpModal="bs-modal" tabindex="-1"
    aria-labelledby="otpModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="saveDraftModalLabel">Login with OTP</h5>
                <button type="button" class="btn-close" (click)="otpModal.hide()" data-mdb-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <form [formGroup]="sendOtpFormGroup" (ngSubmit)="onSubmitSendOtpFormGroup(sendOtpFormGroup.value)">
            <div class="modal-body">
              <div class="form-group">
                <div class="form-floating" [ngClass]="{'is-invalid': sendOtpFormGroup.controls['email'].invalid && sendOtpFormGroup.controls['email'].dirty}">
                  <input type="email" [ngClass]="{'is-invalid': sendOtpFormGroup.controls['email'].invalid && sendOtpFormGroup.controls['email'].dirty}" name="email" class="form-control" formControlName="email" maxlength="50"  id="floatingInputEmail"  placeholder="{{'login.sendOtpForm.email.control_label' | translate }}">
                  <label for="floatingInputEmail">{{'login.sendOtpForm.email.control_label' | translate }}</label>
                </div>
                <div class="invalid-feedback" *ngIf="sendOtpFormGroup.controls['email'].invalid && sendOtpFormGroup.controls['email'].dirty">
                  <span *ngIf="sendOtpFormGroup.controls['email'].errors.required">{{'login.sendOtpForm.email.validation.required' | translate }}</span>
                  <span *ngIf="sendOtpFormGroup.controls['email'].errors.pattern && !sendOtpFormGroup.controls['email'].errors.required && !sendOtpFormGroup.controls['email'].errors.maxlength && !sendOtpFormGroup.controls['email'].errors.minlength">{{'login.sendOtpForm.email.validation.invalid' | translate }}</span>
                  <span *ngIf="sendOtpFormGroup.controls['email'].errors.minlength && !sendOtpFormGroup.controls['email'].errors.required">{{'login.sendOtpForm.email.validation.minlength' | translate }}</span>
                  <span *ngIf="sendOtpFormGroup.controls['email'].errors.maxlength">{{'login.sendOtpForm.email.validation.maxlength' | translate }}</span>
                </div>
                <div *ngIf="!isShowOtpFormGroup && !isUserNotRegistered" class="modal-footer">
                  <button (click)="onHideOtpModal()" class="btn btn-outline-primary" type="button">{{'login.otpModal.closeBtn' | translate }}</button>
                  <button class="btn btn-primary" type="submit">{{'login.otpModal.sendBtn' | translate }}</button>
          </div>
              </div>
            </div>
          </form>
          <div class="modal-body" *ngIf="isUserNotRegistered">
            <span class="alert alert-info"> User with {{sendOtpFormGroup.value.email}} is not yet registered</span>
          <br/><br/>
            <div class="modal-footer">
            <button (click)="onHideOtpModal()" class="btn btn-outline-primary" type="button">{{'login.otpModal.closeBtn' | translate }}</button>
           </div>
          
          </div>
          <div class="modal-body" *ngIf="isShowOtpFormGroup && !isUserNotRegistered">
            <form [formGroup]="oneTimePasswordFormGroup" (ngSubmit)="onSubmitOtpFormGroup(oneTimePasswordFormGroup.value)">
              
                <div class="form-group">
                  <div class="form-floating" [ngClass]="{'is-invalid': oneTimePasswordFormGroup.controls['otpContent'].invalid && oneTimePasswordFormGroup.controls['otpContent'].dirty}">
                    <input type="number" [ngClass]="{'is-invalid': oneTimePasswordFormGroup.controls['otpContent'].invalid && oneTimePasswordFormGroup.controls['otpContent'].dirty }"  class="form-control" formControlName="otpContent" maxlength="50"  id="floatingInputOtp"  placeholder="{{'login.sendOtpForm.otp.control_label' | translate }}">
                    <label for="floatingInputOtp">{{'login.sendOtpForm.otp.control_label' | translate }}</label>
                  </div>
                  <div class="invalid-feedback" *ngIf="oneTimePasswordFormGroup.controls['otpContent'].invalid && oneTimePasswordFormGroup.controls['otpContent'].dirty">
                      <span *ngIf="oneTimePasswordFormGroup.controls['otpContent'].errors.required">{{'login.sendOtpForm.otp.validation.required' | translate }}</span>
                      <span *ngIf="oneTimePasswordFormGroup.controls['otpContent'].errors.minlength && !oneTimePasswordFormGroup.controls['otpContent'].errors.required">{{'login.sendOtpForm.otp.validation.minlength' | translate }}</span>
                      <span *ngIf="oneTimePasswordFormGroup.controls['otpContent'].errors.maxlength">{{'login.sendOtpForm.otp.validation.maxlength' | translate }}</span>
                    </div>
                </div>              
                <div class="modal-footer">
                  <button (click)="onHideOtpModal()" class="btn btn-outline-primary" type="button">{{'login.otpModal.closeBtn' | translate }}</button>
                  <button class="btn btn-primary" type="submit">{{'login.oneTimePasswordForm.confirmBtn' | translate }}</button>
                </div>
              </form>
            </div>
        </div>
    </div>
</div>
<!--/. help Modal -->



